import React from "react";
import './footer.css';

const Footer = () => {
    return (
        <div className="main-footer">
            <div className="copy-right-box">
                <div className="text">© Sitera 2024. All rights reserved.</div>
                <div className="text">Data Protection</div>
            </div>
            <div className="other-info-box">
                <div className="column">
                    <div className="row">Solutions</div>
                    <div className="row">Our approach</div>
                    <div className="row">About us</div>
                </div>
                <div className="column">
                    <div className="row">Let's talk</div>
                    <div className="row">FAQ</div>
                </div>
            </div>
            <div className="title">SITERA</div>
        </div>
    )
};

export default Footer;
