import React, { useState } from "react";
import './solutions.css';
import { ReactComponent as LinkArrowIcon } from "../../images/icons/link-arrow-icon.svg";
import { ReactComponent as ArrowDown } from "../../images/icons/arrow-down.svg";
import { ReactComponent as ArrowTop } from "../../images/icons/arrow-top.svg";
import { CSSTransition, SwitchTransition } from "react-transition-group";

const Solutions = () => {
    const [showBranding, setShowBranding] = useState(true);
    const [showDevelopment, setShowDevelopment] = useState(false);
    const [showSupport, setShowSupport] = useState(false);
    return (
        <div className="solutions-container">
            <div className="title">Solutions</div>
            <div className="solutions-content">
                <div className="btn-nav-box">
                    <button className={`${showBranding ? 'active' : null}`} onClick={() => {
                        setShowBranding(true);
                        setShowDevelopment(false);
                        setShowSupport(false);
                    }}>
                        <span className="text">Branding</span>
                        <div className="btn-background"></div>
                    </button>
                    <button className={`${showDevelopment ? 'active' : null}`} onClick={() => {
                        setShowDevelopment(true);
                        setShowBranding(false);
                        setShowSupport(false);
                    }}>
                        <span className="text">Development</span>
                        <div className="btn-background"></div>
                    </button>
                    <button className={`${showSupport ? 'active' : null}`} onClick={() => {
                        setShowSupport(true);
                        setShowBranding(false);
                        setShowDevelopment(false);
                    }}>
                        <span className="text">Support</span>
                        <div className="btn-background"></div>
                    </button>
                </div>
                <div className="btn-nav-box adaptive">
                    <div className={`select-box ${showBranding ? 'active' : null}`}>
                        <button onClick={() => {
                            setShowBranding(true);
                            setShowDevelopment(false);
                            setShowSupport(false);
                        }}>
                            <span className="text">Branding</span>
                            <div className="icon"><ArrowDown /></div>
                            <div className="icon"><ArrowTop /></div>
                            <div className="btn-background"></div>
                        </button>
                        <SwitchTransition mode="out-in">
                            <CSSTransition timeout={200} key={showBranding} classNames="transition-select-box">
                                {showBranding ?
                                    <div className="select-information-box">
                                        <div className="title">More than just a logo</div>
                                        <div className="text">
                                            Building a distinctive brand that resonates with your target audience begins
                                            by distilling the essence of your company into a compelling narrative.
                                            <p>
                                                We're dedicated to aligning your brand with the values and vision that propel your company forward.
                                            </p>
                                            <p>
                                                Our process is collaborative and transparent, involving:
                                            </p>
                                        </div>
                                        <div className="link-box">
                                            <div className="column">
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Strategy</div>
                                                </div>
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Design & Development</div>
                                                </div>
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Implementation</div>
                                                </div>
                                            </div>
                                            <div className="column">
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Guidelines</div>
                                                </div>
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Review</div>
                                                </div>
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Refine</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div></div>
                                }
                            </CSSTransition>
                        </SwitchTransition>
                    </div>
                    <div className={`select-box ${showDevelopment ? 'active' : null}`}>
                        <button onClick={() => {
                            setShowDevelopment(true);
                            setShowBranding(false);
                            setShowSupport(false);
                        }}>
                            <span className="text">Development</span>
                            <div className="icon"><ArrowDown /></div>
                            <div className="icon"><ArrowTop /></div>
                            <div className="btn-background"></div>
                        </button>
                        <SwitchTransition mode="out-in">
                            <CSSTransition timeout={200} key={showDevelopment} classNames="transition-select-box" >
                                {showDevelopment ?
                                    <div className="select-information-box">
                                        <div className="title">Crafting functional digital spaces</div>
                                        <div className="text">
                                            Crafting functional digital spaces is at the core of our Development
                                            services.
                                            <p>
                                                We follow a meticulous process to ensure your website or web application
                                                not only meets but exceeds expectations:
                                            </p>
                                        </div>
                                        <div className="link-box">
                                            <div className="column">
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Coding</div>
                                                </div>
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Quality Assurance</div>
                                                </div>
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Testing</div>
                                                </div>
                                            </div>
                                            <div className="column">
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Optimization</div>
                                                </div>
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Review</div>
                                                </div>
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Maintance</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div></div>
                                }
                            </CSSTransition>
                        </SwitchTransition>
                    </div>
                    <div className={`select-box ${showSupport ? 'active' : null}`}>
                        <button onClick={() => {
                            setShowSupport(true);
                            setShowBranding(false);
                            setShowDevelopment(false);
                        }}>
                            <span className="text">Support</span>
                            <div className="icon"><ArrowDown /></div>
                            <div className="icon"><ArrowTop /></div>
                            <div className="btn-background"></div>
                        </button>
                        <SwitchTransition mode="out-in">
                            <CSSTransition timeout={200} key={showSupport} classNames="transition-select-box" >
                                {showSupport ?
                                    <div className="select-information-box">
                                        <div className="title">Sustaining your digital succes</div>
                                        <div className="text">
                                            We believe that our commitment to your project extends far beyond the
                                            initial development phase.
                                            <p>
                                                Our Support services are meticulously crafted to guarantee the long-term
                                                success and effectiveness of your digital presence. This commitment
                                                manifests through ongoing assistance, ensuring your website or application
                                                stays relevant and performs at its best.
                                            </p>
                                        </div>
                                        <div className="link-box">
                                            <div className="column">
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Post-Launch Evaluation</div>
                                                </div>
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Regular Updates</div>
                                                </div>
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Security Monitoring</div>
                                                </div>
                                            </div>
                                            <div className="column">
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Optimization</div>
                                                </div>
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Customer Support</div>
                                                </div>
                                                <div className="row">
                                                    <div className="icon"><LinkArrowIcon /></div>
                                                    <div className="text">Customer Support</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div></div>
                                }
                            </CSSTransition>
                        </SwitchTransition>
                    </div>

                </div>
                <div className="hr-box">
                    <hr />
                </div>
                <div className="active-information-box">
                    <div className="title">More than just a logo</div>
                    <div className="text">
                        Building a distinctive brand that resonates with your target audience begins
                        by distilling the essence of your company into a compelling narrative.
                        <p>
                            We're dedicated to aligning your brand with the values and vision that propel your company forward.
                        </p>
                        <p>
                            Our process is collaborative and transparent, involving:
                        </p>
                    </div>
                    <div className="link-box">
                        <div className="column">
                            <div className="row">
                                <div className="icon"><LinkArrowIcon /></div>
                                <div className="text-box">
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showBranding} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showBranding ? <div className="text">Strategy</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showDevelopment} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showDevelopment ? <div className="text">Coding</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showSupport} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showSupport ? <div className="text">Post-Launch Evaluation</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                </div>
                            </div>
                            <div className="row">
                                <div className="icon"><LinkArrowIcon /></div>
                                <div className="text-box">
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showBranding} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showBranding ? <div className="text">Design & Development</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showDevelopment} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showDevelopment ? <div className="text">Quality Assurance</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showSupport} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showSupport ? <div className="text">Regular Updates</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                </div>
                            </div>
                            <div className="row">
                                <div className="icon"><LinkArrowIcon /></div>
                                <div className="text-box">
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showBranding} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showBranding ? <div className="text">Implementation</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showDevelopment} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showDevelopment ? <div className="text">Testing</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showSupport} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showSupport ? <div className="text">Security Monitoring</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="row">
                                <div className="icon"><LinkArrowIcon /></div>
                                <div className="text-box">
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showBranding} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showBranding ? <div className="text">Guidelines</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showDevelopment} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showDevelopment ? <div className="text">Optimization</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showSupport} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showSupport ? <div className="text">Optimization</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                </div>
                            </div>
                            <div className="row">
                                <div className="icon"><LinkArrowIcon /></div>
                                <div className="text-box">
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showBranding} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showBranding ? <div className="text">Review</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showDevelopment} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showDevelopment ? <div className="text">Review</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showSupport} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showSupport ? <div className="text">Customer Support</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                </div>
                            </div>
                            <div className="row">
                                <div className="icon"><LinkArrowIcon /></div>
                                <div className="text-box">
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showBranding} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showBranding ? <div className="text">Refine</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showDevelopment} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showDevelopment ? <div className="text">Maintenance</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                    <SwitchTransition mode={'in-out'}>
                                        <CSSTransition key={showSupport} timeout={500} classNames="transition-text" unmountOnExit>
                                            {showSupport ? <div className="text">Review</div> : <></>}
                                        </CSSTransition>
                                    </SwitchTransition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Solutions;
