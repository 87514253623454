import React from "react";
import './ourApproach.css';
import { ReactComponent as ArrowDown } from "../../images/icons/arrow-down.svg";

const OurApproach = () => {
    return (
        <div className="main-our-approach">
            <div className="block-title">Our approach</div>
            <div className="title-box">
                <div className="title">Three Simple Steps to Partner with Us</div>
                <div className="sub-title">Ready to launch your project? Let's break it down into easy-to-follow stages.</div>
            </div>
            <ul className="steps-list">
                <li className="step-item">
                    <div className="item-title-box">
                        <div className="title">Discuss</div>
                        <div className="icon"><ArrowDown /></div>
                        <div className="number">01</div>
                    </div>
                    <div className="text">
                        Engage with our team to bring your vision to life.
                        Let our Project Manager explore and identify the most
                        suitable solutions for your unique needs.
                    </div>
                    <ul className="info-list">
                        <li className="info-item">Product Observation: "Assessing Your Product's Potential"</li>
                        <li className="info-item">Define a Problem: "Identifying Key Challenges"</li>
                        <li className="info-item">Solving Difficulties: "Crafting Solutions to Overcome Obstacles"</li>
                    </ul>
                </li>
                <li className="step-item">
                    <div className="item-title-box">
                        <div className="title">Plan</div>
                        <div className="icon"><ArrowDown /></div>
                        <div className="number">02</div>
                    </div>
                    <div className="text">
                        We utilize research to strategize the best way forward.
                        Our collaborative brainstorming sessions ensure we align with
                        your project's objectives and fulfill your expectations.
                    </div>
                    <ul className="info-list">
                        <li className="info-item">Site-mapping: "Structuring Your Site's Journey"</li>
                        <li className="info-item">Mood-boarding: "Visualizing the Project's Essence"</li>
                        <li className="info-item">Brainstorming: "Generating Innovative Ideas"</li>
                    </ul>
                </li>
                <li className="step-item">
                    <div className="item-title-box">
                        <div className="title">Launch</div>
                        <div className="icon"><ArrowDown /></div>
                        <div className="number">03</div>
                    </div>
                    <div className="text">
                        Seize the day and kick-start your project without delay.
                        We ensure that no critical detail is overlooked,
                        and establish a clear timeline to set the stage for a successful launch work.
                    </div>
                    <ul className="info-list">
                        <li className="info-item">Requirement Met: "Meeting Every Specification"</li>
                        <li className="info-item">Design Process: "Refining the Creative Path"</li>
                        <li className="info-item">Defining Style: "Shaping the Aesthetic Identity"</li>
                    </ul>
                </li>
            </ul>
        </div>
    )
};

export default OurApproach;
