import React from "react";
import './aboutUs.css';

const AboutUs = () => {
    return (
        <div className="main-about-us">
            <div className="block-title">About us</div>
            <div className="info-box">
                <div className="column">
                    <div className="row">
                        <div className="info-item">
                            <div className="amount">27</div>
                            <span>Orders</span>
                            <span>This</span>
                            <span>Year</span>
                        </div>
                        <div className="info-item">
                            <div className="title">Clients</div>
                            <div className="text-box">
                                <div className="number">20+</div>
                                <div className="text">
                                    Satisfied clients
                                    <br></br> worldwide.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="number">36<span> hours/week</span></div>
                        <div className="text">
                            Optimizing websites for
                            <br></br> enhanced user experience
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="title-box">
                        <span>Design</span>
                        <span>Develop</span>
                        <span>Inspire</span>
                    </div>
                </div>
            </div>

        </div>
    )
};

export default AboutUs;
