import React from "react";
import MainPage from "../Pages/MainPage/MainPage";

const MainLayout = () => {
    return (
        <div>
            <MainPage />
        </div>
    )
};

export default MainLayout;
