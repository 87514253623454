import React from "react";
import './info.css';

const Info = () => {
    return (
        <div className="main-info">
            <div className="info-box">
                <div className="hint">Web development that merges artistry and utility with modern tech.</div>
                <div className="article-box">
                    <article>
                        <div className="title">A web development powerhouse</div>
                        <div className="text">
                            Beginning in 2023, we embarked on a journey to realize
                            a vision of creating a company that would move the
                            world forward. Sitera was born out of a passion for
                            advancing digital spaces with practical, yet innovative
                            web solutions. We are a team of dedicated
                            professionals who believe in making a positive impact
                            by helping businesses establish a commanding online
                            presence through our services.
                        </div>
                    </article>
                    <article>
                        <div className="title">Branding & development</div>
                        <div className="text">
                            At Sitera, we understand that a company's
                            digital presence is its digital handshake.
                            Our comprehensive suite of services
                            Branding, Development, and Support – are
                            crafted to give your business a distinct
                            voice, a robust platform, and a support
                            system that never sleeps.
                        </div>
                    </article>
                </div>
            </div>
        </div>
    )
};

export default Info;
