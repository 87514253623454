import React from "react";
import './description.css';

const Description = () => {
    return (
        <div className="main-description">
            <div className="animation-hr-box"><hr /></div>
            <div className="animation-hr-box"><hr /></div>
            <div className="animation-hr-box"><hr /></div>
            <div className="description-box">
                <div className="animation-box">
                    <div className="logo-title">Sitera Studio</div>
                </div>
                <div className="animation-box">
                    <h2 className="title">Design digital</h2>
                </div>
                <div className="animation-box">
                    <h3 className="sub-title">success stories.</h3>
                </div>
                <div className="animation-box">
                    <div className="text">
                        Crafting custom websites for
                        <br></br> diverse business solutions.
                        <br></br> Start Your story from Your Website.
                    </div>
                </div>
            </div>
            <div className="btn-box">
                <button>
                    <div className="text">Let's work</div>
                </button>
            </div>
        </div>
    )
};

export default Description;
