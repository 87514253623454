import React from "react";
import './letsTalk.css';

const LetsTalk = () => {
    return (
        <div className="main-lets-talk">
            <div className="block-title">Let’s talk</div>
            <div className="info-box">
                <div className="inputs-container">
                    <div className="title">HELLO,</div>
                    <label htmlFor="name" className="input-box">
                        <div className="text">MY NAME IS</div>
                        <div className="border-container">
                            <input id="name" type="text" placeholder="Enter your name here" autoComplete="off" />
                            <div className="dashed-border"></div>
                        </div>
                    </label>
                    <label htmlFor="email" className="input-box">
                        <div className="text">HERE IS MY EMAIL</div>
                        <div className="border-container">
                            <input id="email" type="text" placeholder="Enter your email here" autoComplete="off" />
                            <div className="dashed-border"></div>
                        </div>
                    </label>
                    <label htmlFor="details" className="input-box">
                        <div className="text">I’M LOOKING FOR</div>
                        <div className="border-container">
                            <input id="details" type="text" placeholder="Enter your project details here" autoComplete="off" />
                            <div className="dashed-border"></div>
                        </div>
                    </label>
                </div>
                <div className="send-btn">
                    <button>Send</button>
                </div>
            </div>
        </div>
    )
};

export default LetsTalk;
