import React from "react";
import './mainPage.css';
import Header from "../../Components/Header/Header";
import Description from "../../Components/Description/Description";
import BackgroundImg from "../../images/main-background.png";
import Solutions from "../../Components/Solutions/Solutions";
import OurApproach from "../../Components/OurApproach/OurApproach";
import AboutUs from "../../Components/AboutUs/AboutUs";
import Info from "../../Components/Info/Info";
import LetsTalk from "../../Components/LetsTalk/LetsTalk";
import Footer from "../../Components/Footer/Footer";

const MainPage = () => {
  return (
    <div className="main-page-container">
      <div className="first-block">
        <img src={BackgroundImg} className="main-background" />
        <Header />
        <Description />
        <div className="opacity-rectangle"></div>
      </div>
      <div className="opacity-rectangle adaptive"></div>
      <div className="opacity-rectangle"></div>
      <div className="second-block">
        <Solutions />
      </div>
      <div className="third-block">
        <OurApproach />
      </div>
      <div className="fourth-block">
        <AboutUs />
      </div>
      <div className="fifth-block">
        <Info />
      </div>
      <div className="rectangle dark"></div>
      <div className="rectangle hell"></div>
      <div className="sixth-block">
        <LetsTalk />
      </div>
      <div className="rectangle hell"></div>
      <div className="rectangle dark"></div>
      <div className="seventh-block">
        <Footer />
      </div>
    </div>
  )
};

export default MainPage;
