import React from "react";
import './header.css';
import { ReactComponent as SiteraIcon } from "../../images/icons/sitera-icon.svg";

const Header = () => {
    return (
        <header className="main-header">
            <div className="logo-box">
                <div className="logo-img"><SiteraIcon /></div>
                <h1 className="title">Sitera</h1>
            </div>
            <nav className="navigation">
                <span>Our service</span>
                <span>About us</span>
                <span>Let's talk</span>
            </nav>
        </header>
    )
};

export default Header;
