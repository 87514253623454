// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/SF-Pro/SF-Pro-Text-Thin.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/SF-Pro/SF-Pro-Text-Light.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/SF-Pro/SF-Pro-Text-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/SF-Pro/SF-Pro-Text-Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/SF-Pro/SF-Pro-Text-Semibold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./fonts/SF-Pro/SF-Pro-Text-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./fonts/SF-Pro/SF-Pro-Text-Black.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  background: #0F0F13;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 100;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("OpenType");
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 300;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("OpenType");
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("OpenType");
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 500;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("OpenType");
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 600;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("OpenType");
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 700;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("OpenType");
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 900;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("OpenType");
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,QAAQ;EACR,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,+DAAkE;AACpE;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,+DAAmE;AACrE;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,+DAAqE;AACvE;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,+DAAoE;AACtE;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,+DAAsE;AACxE;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,+DAAkE;AACpE;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,+DAAmE;AACrE","sourcesContent":["body {\n  margin: 0;\n  background: #0F0F13;\n  overflow-x: hidden;\n}\n\nbody::-webkit-scrollbar {\n  width: 0;\n  background: transparent;\n}\n\n@font-face {\n  font-family: 'SF Pro';\n  font-weight: 100;\n  src: url(\"./fonts/SF-Pro/SF-Pro-Text-Thin.otf\") format(\"OpenType\");\n}\n\n@font-face {\n  font-family: 'SF Pro';\n  font-weight: 300;\n  src: url(\"./fonts/SF-Pro/SF-Pro-Text-Light.otf\") format(\"OpenType\");\n}\n\n@font-face {\n  font-family: 'SF Pro';\n  font-weight: 400;\n  src: url(\"./fonts/SF-Pro/SF-Pro-Text-Regular.otf\") format(\"OpenType\");\n}\n\n@font-face {\n  font-family: 'SF Pro';\n  font-weight: 500;\n  src: url(\"./fonts/SF-Pro/SF-Pro-Text-Medium.otf\") format(\"OpenType\");\n}\n\n@font-face {\n  font-family: 'SF Pro';\n  font-weight: 600;\n  src: url(\"./fonts/SF-Pro/SF-Pro-Text-Semibold.otf\") format(\"OpenType\");\n}\n\n@font-face {\n  font-family: 'SF Pro';\n  font-weight: 700;\n  src: url(\"./fonts/SF-Pro/SF-Pro-Text-Bold.otf\") format(\"OpenType\");\n}\n\n@font-face {\n  font-family: 'SF Pro';\n  font-weight: 900;\n  src: url(\"./fonts/SF-Pro/SF-Pro-Text-Black.otf\") format(\"OpenType\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
